import React from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideIn = keyframes`
  from { transform: translateY(-50px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const PopupWrapper = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: rgba(0,0,0,0.5);
  animation: ${fadeIn} 0.1s ease-out;  
`;

const PopupTable = styled.div`
  width: 90%;
  max-width: 400px;
  padding: 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.15);
  display: flex;
  flex-direction: column;
  gap: 20px;
  animation: ${slideIn} 0.1s ease-out;

  @media screen and (max-width: 500px) {
    width: calc(100% - 40px);
  }
`;

const PopupContent = styled.div`
  flex: 1;
`;

const MessageArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: #333;
  text-align: center;
  margin: 0;
  user-select: none;
`;

const Message = styled.p`
  font-size: 16px;
  line-height: 1.6;
  color: #555;
  text-align: center;
  margin: 0;
`;

const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  user-select: none;
`;

const PopupButton = styled.button`
  padding: 10px 25px;
  border: none;
  border-radius: 5px;
  background-color: #3498db;
  color: white;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.1s ease;

  &:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(0,0,0,0.15);
  }

  &:active {
    transform: translateY(0);
  }
`;

function Popup({ message, title, onClose, $preventBackgroundClose = true }) {
  const handleWrapperClick = (e) => {
    if (!$preventBackgroundClose && e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <PopupWrapper onClick={handleWrapperClick}>
      <PopupTable>
        <PopupContent>
          <MessageArea>
            {title && <Title>{title}</Title>}
            <Message>{message}</Message>
          </MessageArea>
        </PopupContent>
        <ButtonArea>
          <PopupButton onClick={onClose}>확인</PopupButton>
        </ButtonArea>
      </PopupTable>
    </PopupWrapper>
  );
}

export default Popup;