import React from 'react';
import styled from 'styled-components';
import { FaHome, FaUser, FaCog, FaSignOutAlt } from 'react-icons/fa';

const MenuWrapper = styled.div`
  flex-basis: ${props => props.$isOpen ? '250px' : '60px'};
  background-color: #2c3e50;
  min-width: 60px;
  margin: 10px 0px;
  overflow-y: auto;
  transition: all 0.1s ease;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);  
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #34495e;
  }

  &::-webkit-scrollbar-thumb {
    background: #7f8c8d;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #95a5a6;
  }

  @media screen and (max-width: 500px) {
    flex-basis: ${props => props.$isOpen ? '250px' : '0px'};
    margin: 0px;
    border-radius: 0;

    &.open {
      flex-basis: 250px;
      flex-shrink: 0;
    }
  }  
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 20px;
  color: #ecf0f1;
  cursor: pointer;
  transition: all 0.2s ease;
  user-select:none;

  &:hover {
    background-color: #34495e;
  }

  svg {
    font-size: 20px;
    margin-right: ${props => props.$isOpen ? '15px' : '0'};
  }

  span {
    display: ${props => props.$isOpen ? 'inline' : 'none'};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

function Menu({ $isOpen }) {
  return (
    <MenuWrapper $isOpen={$isOpen}>
      <MenuItem $isOpen={$isOpen}>
        <FaHome />
        <span>홈</span>
      </MenuItem>
      <MenuItem $isOpen={$isOpen}>
        <FaUser />
        <span>프로필</span>
      </MenuItem>
      <MenuItem $isOpen={$isOpen}>
        <FaCog />
        <span>설정</span>
      </MenuItem>
      <MenuItem $isOpen={$isOpen}>
        <FaSignOutAlt />
        <span>로그아웃</span>
      </MenuItem>
    </MenuWrapper>
  );
}

export default Menu;